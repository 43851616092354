import React, { ReactElement } from 'react';

import Popular from '@components/Popular';
import ServicesList from '@components/ServicesList/UserList';

import { styled } from '@linaria/react';
import { fontMontserrat, fontColorDefault } from '@rambler-help/shared';

const MainPageStyled = styled.div`
  h1 {
    margin: 30px 0;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 38px;
    line-height: 45px;
    font-weight: 800;
  }
  h2 {
    margin: 30px 0 20px;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
  }
  @media screen and (max-width: 1020px) {
    & {
      width: 760px;
      padding: 0 20px;
    }
  }
`;

const Main = (): ReactElement => {
  return (
    <MainPageStyled>
      <h1>Помощь в работе с сервисами Рамблера</h1>
      <Popular />
      <h2>Помощь по сервисам</h2>
      <ServicesList />
    </MainPageStyled>
  );
};

export default Main;
