import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { EProjectIcon } from '@rambler-help/shared';
import { Wrapper } from '@rambler-help/components';
import { List } from '@rambler-help/components';
import { StaticProject } from '@rambler-help/components';
import { useRootStore } from '@stores/index';

const BIG_ITEMS_COUNT = 9;

const UserList = observer((): ReactElement => {
  const { projects } = useRootStore();
  return (
    <Wrapper>
      <List>
        {projects.projects.slice(0, BIG_ITEMS_COUNT).map(project => (
          <StaticProject
            key={project.id}
            data={{
              type: 'top',
              icon: project.icon as EProjectIcon,
              href: '/' + project.slug,
              title: project.title,
              description: project.descriptionShort || '',
            }}
          />
        ))}
      </List>
      <List>
        {projects.projects.slice(BIG_ITEMS_COUNT).map(project => (
          <StaticProject
            key={project.id}
            data={{
              icon: project.icon as EProjectIcon,
              href: '/' + project.slug,
              title: project.title,
              description: project.descriptionShort || '',
            }}
          />
        ))}
      </List>
    </Wrapper>
  );
});

export default UserList;
